<template>
  <v-form
    ref="webNotificationEditForm"
    v-model="valid"
    lazy-validation>
    <v-container
      grid-list-xl>
      <v-layout
        fluid
        pt-0
        wrap>
        <v-flex xs12>
          <app-card
            :footer="true"
            heading="Web notification information"
            col-classes="xl12 lg12 md12 sm12 xs12"
            custom-classes="mb-0"
          >
            <div>
              <rotate-square2 v-if="loading" />
              <v-container
                v-else
                grid-list-xl>
                <v-layout
                  row
                  wrap>
                  <v-flex
                    xs12
                    sm6>
                    <v-text-field
                      :max-length="TEXT_FIELD_MAX_LENGTH"
                      v-model="webNotification.title"
                      :rules="[v => Boolean(v) || 'Please provide a title']"
                      type="text"
                      label="Title"
                    />
                  </v-flex>
                  <v-flex
                    xs12
                    sm6>
                    <v-text-field
                      :max-length="TEXT_FIELD_MAX_LENGTH"
                      v-model="webNotification.text"
                      :rules="[v => Boolean(v) || 'Please provide a text']"
                      type="text"
                      label="Text"
                    />
                  </v-flex>
                  <v-flex
                    xs12
                    sm6>
                    <v-select
                      :items="typesList"
                      v-model="webNotification.type"
                      :rules="[v => Boolean(v) || 'Please provide a type']"
                      label="Select type"
                    />
                  </v-flex>
                  <v-flex
                    xs12
                    sm2>
                    <v-switch
                      v-model="webNotification.enabled"
                      label="Enabled"
                    />
                  </v-flex>
                  <v-flex
                    v-if="roleList"
                    xs12
                    sm12>
                    <v-select
                      :items="Object.keys(roleList)"
                      :rules="[v => v.length > 0 || 'Please select at least 1 user role']"
                      v-model="webNotification.roles"
                      multiple
                      label="Roles"
                    />
                  </v-flex>
                </v-layout>
              </v-container>
            </div>
            <template slot="footer">
              <v-btn
                color="primary"
                class="mr-3"
                @click="saveFunction()"
              >Save</v-btn>
            </template>
          </app-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-form>
</template>

<script>
import { WEB_NOTIFICATION_TYPES, TEXT_FIELD_MAX_LENGTH } from '../../../../constants/common'
import CrudTable from '../../../../modules/admin/CrudTable.vue'
import Request from '../../../../helpers/request'
import Roles from '../../../../helpers/roles'


export default {
  components: { CrudTable },
  mixins: [Request, Roles],
  data () {
    return {
      valid: true,
      loading: true,
      data: [],
      typesList: WEB_NOTIFICATION_TYPES,
      TEXT_FIELD_MAX_LENGTH,
      editDialog: {
        display: false,
        saveFunction: () => {},
        data: {}
      },
      files: {},
      imageData: null,
      roleList: []
    }
  },
  async beforeMount () {
    this.requestData()
    try {
      this.roleList = await this.fetchRoles()
    } catch (e) {
      throw new Error(e)
    }
  },
  methods: {
    requestData () {
      this.request('GET', `/web-notifications/${this.$route.params.id}`, {}, ({ data }) => {
        this.webNotification = data
      }, (loader) => { this.loading = loader })
    },
    saveFunction () {
      if (this.$refs.webNotificationEditForm.validate()) {
        let body = {
          data: {
            'title': this.webNotification.title,
            'text': this.webNotification.text,
            'type': this.webNotification.type,
            'enabled': this.webNotification.enabled,
            'roles': this.webNotification.roles
          }
        }

        this.request('PATCH', `/web-notifications/${this.webNotification.id}`, body, ({ data }) => {
          this.$notify({
            group: 'topRight',
            type: 'success',
            text: 'Web notfication has been changed'
          })

          this.$router.push('/tables/web-notifications')
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.image-preview {
    img {
      width: 124px;
      height: 124px;
      background-color: white;
      border: 1px solid #DDD;
      padding: 5px;
    }
}
</style>
